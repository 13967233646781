@import "../../main/variables";

.navbar {
  width: 100%;
  height: 60px;
  border-bottom: 2px solid #eee;
  position: relative;

  &__container {
    margin: auto;
    padding: 0 10px;
    width: 100%;
    height: 60px;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    font-size: 16px;
    letter-spacing: 2px;

    &:hover {
      color: #2ec4b6;
    }
  }

  &__menu-button {
    // border: 1px solid #555;
  }

  // Mobile Menu Slidebar
  &__mobile-menu {
    background-color: white;
    padding: 10px 15px;
    width: 100%;
    height: calc(100vh - 60px);
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    position: absolute;
    z-index: 10;

    &.active {
      transform: translateX(0%);
    }
  }
}

@media screen and (min-width: 768px) {
  .navbar {
    &__logo {
      font-size: 18px;
    }

    &__menu-button {
      display: none;
    }

    &__mobile-menu {
      display: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    &__logo {
      font-size: 24px;
    }
  }
}
