.card-items-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  place-items: center;
}

// .box-margin {
//   margin: 0 1rem;
// }
