@import "../../main/variables";

.hero-container {
  // border: 1px dotted red;
  width: 100%;
  height: calc(90vh - 60px);
  margin-bottom: 30px;
}

.hero__image {
  background-image: linear-gradient(
      to bottom,
      #000000ab,
      #3b3b3ba6,
      #777777a8,
      #b9b9b94f,
      #ffffff81
    ),
    url("../../images/hero2.jpg");
  height: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: grid;
  place-items: start center;

  .hero__text-box {
    padding: 0 10px;
    width: 100%;
    max-width: 1024px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5%;
  }

  .hero__title {
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 1px;
    font-family: $font-hero;
  }

  .hero__sub-title {
    margin: 20px 0px;
    font-family: $font-hero-subtitle;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
  }
}
