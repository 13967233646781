.card {
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  .card__figure {
    display: grid;
    place-items: center;
    flex: 1 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card__body {
    display: grid;
    place-items: center;
    flex: 2 1;
    padding: 5rem 0.5rem;
    background: linear-gradient(
      to right,
      #dff9fb,
      #cbe6ec,
      #b7d4dd,
      #a5c1cf,
      #95afc0
    );

    .card__aside {
      width: 100%;
      max-width: 400px;

      .card__title {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
      }

      .small-break-line {
        margin: 2rem 0;
        width: 2rem;
      }

      .card__para-bold {
        font-weight: bold;
        font-style: italic;
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }

      .card__para-normal {
        font-style: italic;
        font-size: 0.8rem;
        margin-bottom: 1rem;
      }

      .card__link {
        text-transform: uppercase;
        font-size: 0.75rem;
        cursor: pointer;
        position: relative;
        color: #872200;

        &::after {
          content: "";
          width: 0%;
          height: 2.5px;
          left: 0;
          bottom: -10px;
          position: absolute;
          background-color: #c4560c;
          transition: all 0.2s ease-in;
        }

        &:hover {
          color: #c4560c;
          font-weight: 500;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
  }
}

.card:last-child {
  margin-bottom: 0;
}

.bkg-color-tertiary {
  background-color: #e3e0d6;
}

@media screen and (min-width: 1020px) {
  .card {
    flex-direction: row;
  }

  .card:nth-of-type(even) {
    .card {
      background: green;
    }
    .card__body {
      order: -1;
      background: linear-gradient(
        to right,
        #95afc0,
        #a5c1cf,
        #b7d4dd,
        #cbe6ec,
        #dff9fb
      );
    }
  }
}
