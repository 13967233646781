@import "../../main/variables";

.form-container {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  color: $font-color-primary;
  // border: 2px dotted red;
}

.form {
  width: inherit;
  max-width: inherit;
  padding: 16px;
  background-color: white;
  border: 2px solid #eee;
  position: relative;

  &__group {
    position: relative;
    height: 40px;
    margin-bottom: 16px;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: 1px solid gray;
    border-radius: 8px;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
    outline: none;
    padding: 20px;
    background: none;

    &:hover {
      border-color: royalblue;
    }

    &:focus {
      border-color: orange;
    }
  }

  &__label {
    position: absolute;
    top: -8px;
    left: 13px;
    font-size: 13px;
    padding: 0 8px;
    background-color: white;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  }

  &__button {
    display: block;
    margin-left: auto;
    padding: 10px 32px;
    background-color: #5e5e00;
    font-family: inherit;
    font-weight: inherit;
    color: white;
    outline: none;
    border: 1px solid gray;
    cursor: pointer;
    transition: background-color 100ms ease-in;

    &:hover {
      background-color: #576574;
    }
  }
}

@media screen and (min-width: 700px) {
  .form {
    &__label {
      position: absolute;
      left: 16px;
      top: 14.4px;
      padding: 0 8px;
      background-color: white;
      transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
    }
  }

  .form__input:focus ~ .form__label,
  .form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
    top: -0.5rem;
    left: 13px;
    font-size: 13px;
  }
}
