@import "./variables";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: white;
  font-family: $font-primary;
  color: #555;
}

section {
  padding: 35px 0;
}

a {
  text-decoration: none;
  color: inherit;
}
