.navbar__mobile-menu__link {
  list-style: none;
  padding: 40px 0;
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  border-bottom: 2px solid #eee;

  a {
    text-decoration: none;
    letter-spacing: 1px;
    position: relative;

    &::after {
      content: "";
      width: 0%;
      height: 2.5px;
      left: 0;
      bottom: -10px;
      position: absolute;
      background-color: #2ec4b6;
      transition: all 0.2s ease-in;
    }

    &:hover {
      color: #2ec4b6;
      font-weight: 500;
    }

    &:hover::after {
      width: 100%;
    }
  }
}
