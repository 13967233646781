.footer {
  margin-top: 35px;
  padding: 5rem 0;
  display: grid;
  place-items: center;
  background: linear-gradient(
    to right,
    #dff9fb,
    #cbe6ec,
    #b7d4dd,
    #a5c1cf,
    #95afc0
  );

  .footer_textbox {
    width: 100%;
    height: auto;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h3 {
      font-size: 0.7rem;
      letter-spacing: 2px;
      margin-bottom: 2rem;
    }

    .footer_icons {
      width: 100%;
      max-width: 150px;
      display: flex;
      justify-content: space-between;
    }
  }
}
