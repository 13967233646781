@import "../../main/variables";

.banner-container {
  width: 100%;
  height: 30vh;
}

.banner__image {
  height: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: grid;
  place-items: center;

  .banner__text-box {
    width: 100%;
    max-width: 1024px;
    height: inherit;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .banner__title {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    font-family: $font-hero;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 320px) {
  .banner__image {
    .banner__title {
      font-size: 21px;
    }
  }
}

@media screen and (min-width: 375px) {
  .banner__image {
    .banner__title {
      font-size: 24px;
    }
  }
}
