.card-item {
  position: relative;
  height: 400px;

  img {
    width: 100%;
    height: 400px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .text-centered {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translate(0%, -50%);
    color: white;
    z-index: 1;
  }
}
